import { memo, useEffect, useMemo } from 'react'
import { useSnapshot } from 'valtio'
import { useStockWeightResource } from '~/modules/chips/useChipResource'
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import StockScoreContribution, { WeightedStockRatioType } from './StockScoreContribution'
import styled from '@emotion/styled'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'

const StockContribtuionTable = memo<ReactProps<{ limit: number }>>(function StockContribtuionTable(
  props,
) {
  const twse = useStockWeightResource('TWSE')
  const twse50 = useSnapshot(staticStore).symbol.tw50

  const orderedTWSEStocks = useMemo(() => {
    return twse
      .filter(([k]) => twse50?.indexOf(k) !== -1)
      .map(([k, v]) => ({ symbol: k, percentage: v.percentage }))
      .sort((a, b) => b.percentage - a.percentage)
  }, [twse, twse50])

  const getWeight = (symbol: string | undefined) =>
    0.01 * (orderedTWSEStocks.find(s => s.symbol === symbol)?.percentage ?? 0.01)

  //與加權指數報價合併,不然會算不出貢獻點數
  const allSymbol = orderedTWSEStocks.map(s => s.symbol).concat(['TSEA'])

  useEffect(() => {
    signalrStore2.addQuote(allSymbol)
    return () => {
      signalrStore2.removeQuote(allSymbol)
    }
  }, [JSON.stringify(allSymbol)])

  const indexValue = useSnapshot(signalrStore2.values.quote)['TSEA']
  const indexClose = indexValue?.close ?? 0
  const value = signalrHooks2.useQuotes(allSymbol)

  const rowData = () => {
    return value.map(data => {
      const quoteChanges = processQuoteToReadable(data as Signalr.ValueOfOHLC)
      const close = data?.close ?? 0
      const prevRef = data?.prevRef ?? 0
      const change = close - prevRef
      const symbol = data?.symbol
      const weight = getWeight(symbol)
      const changePercent = quoteChanges.closeChangePercent ?? 0
      const indexChanged = (indexClose * getWeight(symbol)) / close
      const contributionPoints = parseFloat((indexChanged * change).toFixed(2))

      return {
        symbol: symbol,
        close: close,
        change: change,
        changePercent: changePercent,
        weight: weight * 100,
        prevRef: prevRef,
        contribution: contributionPoints,
      } as WeightedStockRatioType
    })
  }

  /** 最大的貢獻點數(絕對值) */
  const maxScore = Math.max(...rowData().map(s => Math.abs(s.contribution)))

  /** 正向貢獻點數 */
  const upData = rowData()
    .filter(s => s.contribution >= 0)
    .sort((a, b) => b.contribution - a.contribution)
    .slice(0, props.limit)

  /** 負向貢獻點數 */
  const dnData = rowData()
    .filter(s => s.contribution < 0)
    .sort((a, b) => a.contribution - b.contribution)
    .slice(0, props.limit)

  return (
    <classes.container>
      {upData && (
        <Teble
          data={upData}
          maxValue={maxScore}
          type='up'
        />
      )}
      {upData && (
        <Teble
          data={dnData}
          maxValue={maxScore}
          type='dn'
        />
      )}
    </classes.container>
  )
})

const Teble = memo<
  ReactProps<{ data: WeightedStockRatioType[]; maxValue: number; type: 'up' | 'dn' }>
>(function Teble(props) {
  const datas = props.data
  return (
    <div css={fill_vertical_cross_center}>
      <StockScoreContribution.DisplayHeader type={props.type} />
      <classes.bodyContainer>
        {datas?.map((data, index) => {
          return (
            <StockScoreContribution.DisplayBody
              data={data}
              maxValue={props.maxValue}
              key={index}
            />
          )
        })}
        {!datas ||
          (datas.length === 0 && <classes.emptyContainer>載入中...</classes.emptyContainer>)}
      </classes.bodyContainer>
    </div>
  )
})

const classes = {
  container: styled.div`
    ${fill_horizontal_all_center};
    min-width: 240px;
    min-height: 160px;

  `,
  emptyContainer: styled.div`
    ${fill_horizontal_all_center};
  `,
  bodyContainer: styled.div`
    ${fill_vertical_cross_center};
    padding: 2px 0px;
    font-size: 12px;
    gap: 2px;
  `,
}

export default {
  classes,
  Display: StockContribtuionTable,
}
