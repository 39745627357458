import { memo } from 'react'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { css } from '@emotion/react'
import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument'
import styled from '@emotion/styled'

export type WeightedStockRatioType = {
  symbol: string
  close: number
  change: number
  changePercent: number
  weight: number
  prevRef: number
  contribution: number
}

/** 標題 */
const StockContribtuionHeader = memo<ReactProps<{ type: 'up' | 'dn' }>>(
  function StockContribtuionHeader(props) {
    const isUpType = props.type === 'up'
    const displayType = isUpType ? '上漲' : '下跌'
    const direction = isUpType ? 'row' : 'row-reverse'
    return (
      <classes.header direction={direction}>
        <classes.title width={64}>{displayType}貢獻</classes.title>
        <classes.title width={36}>點數</classes.title>
      </classes.header>
    )
  },
)

/** item */
const ContributionBody = memo<ReactProps<{ data: WeightedStockRatioType; maxValue: number }>>(
  function ContributionBody(props) {
    const symbolName = fr_instrument.getName(props.data.symbol) //沒有正確返回商品名稱

    const isUpData = props.data.contribution >= 0
    const fill = isUpData ? '#cc0000' : '#00cc00'
    const plusSign = isUpData ? '+' : ''
    const direction = isUpData ? 'row' : 'row-reverse'
    const justifyBar = isUpData ? 'end' : 'start'
    const justifySymbol = !isUpData ? 'end' : 'start'

    return (
      <classes.body
        direction={direction}
        justify={justifyBar}
      >
        <ColumnarBar
          widthValue={props.data.contribution}
          maxValue={props.maxValue}
          bgFill={fill}
        />
        <classes.symbolCode justify={justifySymbol}>{props.data.symbol}</classes.symbolCode>
        <classes.symbolCode justify={justifySymbol}>{symbolName}</classes.symbolCode>
        <classes.barContent justify={justifyBar}>
          <classes.barValue fill={fill}>
            {plusSign}
            {props.data.contribution.toFixed(2)}
          </classes.barValue>
        </classes.barContent>
      </classes.body>
    )
  },
)

/** 橫向柱狀條 根據貢獻點數調整長度 */
const ColumnarBar = memo<ReactProps<{ widthValue: number; maxValue: number; bgFill: string }>>(
  function Bar(props) {
    const widthValue = Math.abs(props.widthValue)
    const maxValue = props.maxValue * 1.03
    return (
      <classes.bar
        bgFill={props.bgFill}
        css={css`
          width: ${(widthValue / maxValue) * 100}%;
        `}
      >
        &nbsp;
      </classes.bar>
    )
  },
)
const classesFill = {
  backgroundFill: '#77777700',
  backgroundFillHover: '#77777744',
}

const classes = {
  //header
  header: styled.div<{ direction: string }>`
    ${fill_horizontal_all_center};
    height: 30px;
    flex-direction: ${props => props.direction};
    font-size: 14px;
    border-bottom: 1px solid #666666;
  `,
  title: styled.div<{ width: number }>`
    ${fill_horizontal_all_center};
    width: ${props => props.width}%;
  `,
  //body
  body: styled.div<{ direction: string; justify: string }>`
    display: flex;
    flex-direction: ${props => props.direction};
    justify-content: ${props => props.justify};
    align-items: center;
    position: relative;
    height: 30px;
    width: 100%;
    background-color: ${classesFill.backgroundFill};
    cursor: pointer;
    padding: 2px;
    border-radius: 2px;
    &:hover {
      background-color: ${classesFill.backgroundFillHover};
    }
  `,
  symbolCode: styled.div<{ justify: string }>`
    ${fill_horizontal_all_center};
    justify-content: ${props => props.justify};
    width: 28%;
    white-space: nowrap;
  `,
  barContent: styled.div<{ justify: string }>`
    ${fill_horizontal_all_center};
    justify-content: ${props => props.justify};
    width: 64%;
    height: 30px;
  `,
  barValue: styled.div<{ fill: string }>`
    ${fill_horizontal_all_center};
    width: 48%;
    height: 30px;
    color: ${props => props.fill};
  `,
  //bar柱狀體
  bar: styled.div<{ bgFill: string }>`
    display: flex;
    position: absolute;
    height: 24px;
    background-color: ${props => props.bgFill}33;
    border-radius: 2px;
  `,
}

export default {
  DisplayHeader: StockContribtuionHeader,
  DisplayBody: ContributionBody,
  classes,
}
